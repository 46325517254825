<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >

    <div v-if="orders !== null">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Count</label>
              <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
            </b-col>

            <!-- Search -->
            <b-col
                cols="12"
                md="4"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Search"
                />
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="orders"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="Nothing found!"
        >

          <!-- Column: Status -->
          <template #cell(isPayed)="data">
            <b-badge
                v-if="data.item.isPayed"
                pill
                class="text-capitalize"
                :variant="`success`"
            >
              Payed
            </b-badge>
            <b-badge
                v-else
                pill
                class="text-capitalize"
                :variant="`danger`"
            >
              Not payed
            </b-badge>
          </template>

          <!-- Column: finalPrice -->
          <template #cell(finalPrice)="data">
            <span>
               {{ data.item.finalPrice }}
            </span>
          </template>

          <!-- Column: totalPrice -->
          <template #cell(totalPrice)="data">
            <span>
               {{ data.item.finalPrice }}
            </span>
          </template>

          <!-- Column: createDate -->
          <template #cell(createAt)="data">
            <span>
              {{data.item.createAt.slice(11,16)}}
              -
              {{createJalaliDate(data.item.createAt.slice(0,10))}}
            </span>
          </template>

          <!-- Column: orderStatus -->
          <template #cell(orderStatus)="data">
            <span v-if="data.item.orderStatus === 1">Pending</span>
            <span v-if="data.item.orderStatus === 2">Waiting for admin</span>
            <span v-if="data.item.orderStatus === 3">Payment in advanced</span>
            <span v-if="data.item.orderStatus === 4">Payed</span>
            <span v-if="data.item.orderStatus === 5">Sorting</span>
            <span v-if="data.item.orderStatus === 6">Packeting</span>
            <span v-if="data.item.orderStatus === 7">Preparing</span>
            <span v-if="data.item.orderStatus === 8">Shipping from origin to destination</span>
            <span v-if="data.item.orderStatus === 9">Shipping completed</span>
            <span v-if="data.item.orderStatus === 10">Order closed</span>

<!--            <b-dropdown-->
<!--                variant="link"-->
<!--                no-caret-->
<!--            >-->
<!--              <template #button-content>-->
<!--                <feather-icon-->
<!--                    icon="ChevronDownIcon"-->
<!--                    size="16"-->
<!--                    class="align-middle text-body"-->
<!--                />-->
<!--              </template>-->
<!--              <b-dropdown-item @click="changeOrderStatus(data.item.orderId,1)">-->
<!--                <span class="align-middle ml-50">ثبت شده</span>-->
<!--              </b-dropdown-item>-->

<!--              <b-dropdown-item @click="changeOrderStatus(data.item.orderId,2)">-->
<!--                <span class="align-middle ml-50">پرداخت شده</span>-->
<!--              </b-dropdown-item>-->

<!--              <b-dropdown-item @click="changeOrderStatus(data.item.orderId,3)">-->
<!--                <span class="align-middle ml-50">ارسال شده</span>-->
<!--              </b-dropdown-item>-->

<!--              <b-dropdown-item @click="changeOrderStatus(data.item.orderId,4)">-->
<!--                <span class="align-middle ml-50">تحویل داده شده</span>-->
<!--              </b-dropdown-item>-->

<!--              <b-dropdown-item @click="changeOrderStatus(data.item.orderId,5)">-->
<!--                <span class="align-middle ml-50">رد شده</span>-->
<!--              </b-dropdown-item>-->
<!--            </b-dropdown>-->
          </template>

          <!-- Column: Actions -->
          <template #cell(delete)="data">
            <b-link   v-b-modal.modal-delete @click="showModal(data.item.orderId)">
              <feather-icon icon="TrashIcon" class="text-danger" size="20"/>
            </b-link>
          </template>

          <template #cell(edit)="data">
            <b-link :to="{ name: 'apps-orders-info', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" size="20"/>
            </b-link>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCounts"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
    <b-modal
        id="modal-delete"
        centered
        ok-title="Delete"
        cancelTitle="Cansel"
        @ok="deleteOrder(deleteItem)"
    >
      <span>Are you sure?</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay,BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {OrderCreateForUI, OrderGetAllRequest} from "@/libs/Api/Order";
import Helper from "@/libs/Helper";
import {OrderChangeStatusRequest} from "@/libs/Api/Order";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {OrderDeleteRequest} from "@/libs/Api/Order";

export default {
  name: "OrdersList",
  title:"Orders list",
  data(){
    return{
      orders:null,
      deleteItem:null,
      currentPage:1,
      totalCounts:null,
      perPage:10,
      overlay:false,
      purchasedByAdmin:null,
      notPurchasedByAdmin:null,
      searchPurchasedByAdmin:'',
      perPageOptions:[10, 20, 30 , 40 , 50],
      myTableColumns : [
        { key: 'id' ,label :'Order Id'},
        { key: 'fullName' ,label :'User'},
        { key: 'orderStatus' ,label :'Order Status'},
        { key: 'finalPrice' ,label :'Final Price'},
        { key: 'totalPrice' ,label :'Total Price'},
        { key: 'currencyTitle' ,label :'Currency'},
        { key: 'isPayed' ,label :'Payment Status'},
        { key: 'createAt' ,label :'Date'},
        // { key: 'delete',label:'حذف سفارش'},
        { key: 'edit',label:'Show Order'},
      ],
      searchQuery:'',
      isAddNewUserSidebarActive:false,
    }
  },
  async created() {
    await this.getOrders()
  },
  methods:{
    async getOrders(){
      let _this = this;
      _this.overlay = true;

      let data = {
        pageNumber:_this.currentPage,
        count:_this.perPage,
      }

      let orderCreateForUI = new OrderCreateForUI(_this);
      orderCreateForUI.setParams(data);
      await orderCreateForUI.fetch(function (content){
        _this.overlay = false;
        _this.orders = content.data.data;
        _this.totalCounts = content.data.total;
      },function (error){
        _this.overlay = false;
        console.log(error);
      })
    },
    async deleteOrder(param){
      let _this = this;
      _this.overlay = true;

      let orderDeleteRequest = new OrderDeleteRequest(_this);
      orderDeleteRequest.setId(param);
      await orderDeleteRequest.fetch(function (content){
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Operation successful`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        _this.getOrders(_this.perPage,_this.currentPage,_this.searchQuery,_this.searchPurchasedByAdmin)
      },function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
    async changeOrderStatus(orderId,status){
      let _this = this;
      _this.overlay = true;
      let data={
        orderId:orderId,
        status:status,
      }

      let orderChangeStatusRequest = new OrderChangeStatusRequest(_this);
      orderChangeStatusRequest.setId(data);
      await orderChangeStatusRequest.fetch(function (content){
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Operation successful`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        _this.getOrders()
      },function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
    makePrettyPrice(param){
      return Helper.prettyPrice(param,0)
    },
    createJalaliDate(param){
      return new Date(param).toLocaleDateString("en-US",);
    },
    showModal(param){
      let _this = this;
      _this.deleteItem = param
    },
  },
  watch:{
    perPage:function (nv,ov){
      this.getOrders();
    },
    currentPage:function (nv,ov) {
      this.getOrders();
    },
    searchQuery:function (nv,ov){
      this.getOrders()
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,

    vSelect,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
